































































































































































import Vue from "vue";
import moment from "moment";
import apiClient from "@/services/apiClient";
import userManager from "@/services/userManager";

export default Vue.extend({
  data() {
    return {
      userManager,
      items: [],
      totalItem: null,
      users: [],
      currentUser: null,
      currentDate: "",
      startDate: "",
      endDate: "",
      loading: false,
      headers: [
        { text: this.$t("PACKAGE_ID"), value: "package_id" },
        { text: this.$t("PACKAGE_BATCH_ID"), value: "package_batch_id" },
        { text: this.$t("TRUCK_ID"), value: "truck_id" },
        { text: "Loại xe", value: "truck_type" },
        { text: "Tiền vận chuyển", value: "fee_transport" },
        { text: "Lợi nhuận thanh toán", value: "profit" },
        { text: "Tổng lương DSVC", value: "salary_dsvc" },
        { text: "Tổng lương DV thanh toán", value: "salary_dvtt" },
        { text: "Ghi chú", value: "money_note" },
        { text: "Ngày về VN", value: "truck_vn_time" },
        { text: "Ngày thanh lý", value: "liquidation_time" },
      ],
      availableTruckTypeMap: {
        tap_13m: "Tạp 13m",
        tap_9m6: "Tạp 9m6",
        tap_17m: "Tạp 17m",
        tap_cont: "Cont tạp",
        tap_cont_20: "Cont tạp 20",
        tap_cont_40: "Cont tạp 40",
        tx: "Tách xe",
        nx: "Nguyên xe",
        nx_13m: "Nguyên xe 13m",
        nx_17m: "Nguyên xe 17m",
        nx_air: "Air",
        nx_cb: "Nguyên cont",
        nx_l: "Nguyên cont lẻ",
        db_cont_20: "Cont 20 đặc biệt",
        db_cont_40: "Cont 40 đặc biệt",
      },
      timeModes: [
        {
          value: "month",
          text: "Tháng",
        },
        {
          value: "custom",
          text: "Tùy chỉnh",
        },
        // {
        //   value: "year",
        //   text: "Năm"
        // }
      ],
      timeMode: "month",
      stages: [
        {
          value: "stage1",
          text: "Ngày 1-10",
        },
        {
          value: "stage2",
          text: "Ngày 11-20",
        },
        {
          value: "stage3",
          text: "Ngày 21-cuối tháng",
        },
        {
          value: "",
          text: "Tất cả",
        },
      ],
      stage: "",
      userMonth: {},
      currentStatus: "dtl",
      availableStatuses: [
        { value: "dtl", text: "Đã thanh lý" },
        // { value: "ctl", text: "Chưa thanh lý" },
        { value: "hdv", text: "Hàng đã về" },
      ],
    };
  },
  watch: {
    currentUser() {
      this.initialize();
    },
    currentDate() {
      this.initialize();
    },
    startDate() {
      this.initialize();
    },
    endDate() {
      this.initialize();
    },
    timeMode() {
      this.initialize();
    },
    stage() {
      this.initialize();
    },
    currentStatus() {
      this.initialize();
    },
  },
  computed: {
    month() {
      return moment(this.currentDate).startOf("month").unix();
    },
    startMonth() {
      return moment(this.startDate).startOf("month").unix();
    },
    endMonth() {
      return moment(this.endDate).endOf("month").unix();
    },
    startTime() {
      let m = moment(this.currentDate).startOf(this.timeMode);
      if (this.timeMode === "custom") {
        m = moment(this.startDate);
      } else if (this.timeMode === "month") {
        if (this.stage) {
          switch (this.stage) {
            case "stage1":
              break;
            case "stage2":
              m = m.add(10, "days");
              break;
            case "stage3":
              m = m.add(20, "days");
              break;
          }
        }
      }
      return m.startOf("day").unix();
    },
    endTime() {
      let m = moment(this.currentDate).endOf(this.timeMode);
      if (this.timeMode === "custom") {
        m = moment(this.endDate);
      } else if (this.timeMode === "month") {
        if (this.stage) {
          m = m.startOf("month");
          switch (this.stage) {
            case "stage1":
              m = m.add(9, "days");
              break;
            case "stage2":
              m = m.add(19, "days");
              break;
            case "stage3":
              m = m.endOf("month");
              break;
          }
        }
      }
      return m.endOf("day").unix();
    },
  },
  methods: {
    async initialize() {
      if (!this.currentUser || !this.currentDate) {
        return;
      }
      this.loading = true;
      if (this.timeMode === "month") {
        this.userMonth = await apiClient.getUserMonth(this.currentUser.id, this.month);
      } else if (this.timeMode === "custom") {
        this.userMonth = await apiClient.getUserMonthInRange(this.currentUser.id, this.startMonth, this.endMonth);
      }
      const options: any = {
        extra: {
          statistics: true,
        },
        report: true,
      };
      if (this.currentUser.id) {
        options.auth_override = {
          id: this.currentUser.id,
          role: "kd",
          codes: this.currentUser.codes || [],
          ids: [],
        };
      }
      switch (this.currentStatus) {
        case "dtl":
          options.extra.liquidation_slip_approve_time = {
            from: this.startTime,
            to: this.endTime,
          };
          break;
        case "ctl":
          options.extra.truck_vn_time = {
            from: this.startTime,
            to: this.endTime,
          };
          options.extra.liquidation_slip_approved = false;
          break;
        case "hdv":
          options.extra.truck_vn_time = {
            from: this.startTime,
            to: this.endTime,
          };
          break;
      }
      let { items } = await apiClient.packageGetAll(options);
      items = items.map((item) => {
        if (item.truck) {
          item.salary_dsvc =
            ((item.truck.type.startsWith("db") && 0.08) || (item.truck.type.startsWith("nx") && 0.02) || 0.04) *
            item.fee_transport;
        }
        item.salary_dvtt = item.profit * 0.2;
        return item;
      });

      this.totalItem = items.reduce(
        (totalItem, item) => {
          totalItem.fee_transport += item.fee_transport;
          totalItem.profit += item.profit;
          totalItem.salary_dsvc += item.salary_dsvc;
          totalItem.salary_dvtt += item.salary_dvtt;
          return totalItem;
        },
        {
          fee_transport: 0,
          profit: 0,
          salary_dsvc: 0,
          salary_dvtt: 0,
        }
      );
      this.totalItem.salary = this.totalItem.salary_dsvc + this.totalItem.salary_dvtt;

      this.items = items;
      this.loading = false;
    },
  },
  async created() {
    this.currentDate = moment().format("YYYY-MM");
    this.startDate = moment().startOf("month").format("YYYY-MM-DD");
    this.endDate = moment().endOf("month").format("YYYY-MM-DD");

    if (userManager.checkRole(["kd"], true)) {
      const userInfo = userManager.getUserInfo();
      const user = {
        id: userInfo.sub,
        name: userInfo.name,
      };
      this.currentUser = user;
      this.users.push(user);
    } else {
      const { items } = await apiClient.userGetAll({
        filters: [
          {
            key: "roles",
            operator: "match",
            value: "kd",
          },
        ],
      });
      items.unshift({
        name: "ALL",
      });
      for (const user of items) {
        this.users.push(user);
      }
    }
  },
});
